/*@value brandGreen from '../../sharedStyles/colors.css';*/

.header {
  margin-bottom: 40px;
}

.cardsRegion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin: 0 -4px 40px;
}

.addAnotherLibraryLabel {
  margin-bottom: 30px;
}

.libraryListRegion {
  margin-bottom: 40px;
}

.libraryListLabel {
  text-transform: none;
  margin-bottom: 10px;
}

.librarySelectRegion {
  text-align: left;
}

.librarySelect {
  border: 1px solid #aaa;
  width: 100%;
}

.geoRegion {
  margin-bottom: 60px;
}

.geoLabel {
  text-transform: none;
  margin-bottom: 20px;
}

.geoButton {
  border-color: #222 !important;
}

.geoButton:hover {
  color: #555;
}

.buttonRegion {
  height: 140px;
}

.doneButton {
  width: 80%;
}
