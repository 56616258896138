@value brandGreen, gray, patronDarkBlue from '../../sharedStyles/colors.css';

.headerContainer {
    composes: responsiveContainer from '../../sharedStyles/layout.css';
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.row :global(.form-group) {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1 1;
    margin: 10px;
}

.form button,
.form :global(.bb-form-submit-button-loader-region) {
    width: 300px;
    margin: 0 auto;
}

.form select {
    height: 40px;
}

@media (max-width: 768px) {
    .row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}

