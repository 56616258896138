.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 50%;
}

.card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: white;
    margin: 0 8px 8px 0;
    padding: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid #777;
    /*box-shadow: 1px 1px 1px 0 #d6d6d6;*/
}

.organizationLogo {
    min-width: 170px;
    min-height: 50px;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.organizationNameContainer {
    min-width: 170px;
    min-height: 50px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.organizationName {
    font-size: 17px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .container {
        width: 100%;
    }

    .organizationLogo {
        min-width: 204px;
        min-height: 60px;
    }

    .organizationNameContainer {
        min-width: 204px;
        min-height: 60px;
    }

    .organizationName {
        font-size: 18px;
    }
}
