@value patronDarkBlue from './colors.css';

.container {
  border-left: 2px dotted #d6d6d6;
  padding-left: 20px;
}

.switchAccount {
  display: inline-block;
  margin: 0 0 2px 0;
}

.addRemoveLibrariesLink {
  display: inline-block;
  color: patronDarkBlue;
  margin: 0 0 10px 0;
  font-size: 14px;
}

.addRemoveLibrariesLink:hover {
  opacity: 0.75;
  text-decoration: none;
  color: patronDarkBlue;
}

.geoLink {
  composes: addRemoveLibrariesLink;
  background: none;
  border: none;
}

.horizontalScroll {
  overflow-x: auto;
  white-space: nowrap;
  vertical-align: top;
}

.cardRegion {
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}
