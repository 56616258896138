@value patronDarkBlue from './colors.css';
@value brandGreen from './colors.css';
.container {
  display: inline-block;
  vertical-align: top;
}
.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  padding: 15px;
  border: 1px solid #dcdcdc;
  -webkit-box-shadow: 1px 1px 1px 0 #d6d6d6;
          box-shadow: 1px 1px 1px 0 #d6d6d6;
  margin: 0 10px 15px 0;
}
.activeCard {
  composes: card;
  border: 1px solid brandGreen;
  cursor: default;
}
.organizationLogo {
  width: 150px;
  height: 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.organizationNameContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.organizationName {
  font-size: 16px;
  font-weight: 300;
}
.switchLink {
  display: block;
  font-weight: 700;
  font-size: 12px;
  margin-top: 12px;
  text-transform: uppercase;
  text-decoration: none;
  background: none;
  border: none;
  color: patronDarkBlue;
}
.switchLinkDisabled {
  composes: switchLink;
  visibility: hidden;
}
.switchLink:hover {
  text-decoration: none;
}
