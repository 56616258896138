@value brandGreen, gray from '../../sharedStyles/colors.css';

.container {
    height: 300px;
    background-size: cover;
    background-position: 50% 50%;
    background-color: black;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.container h1 {
    text-align: center;
    color: white;
    font-size: 58px;
    margin: 50px;
    font-weight: 300;
}

@media (max-width: 992px) {
    .container h1 {
        font-size: 38px;
        margin: 30px;
    }
}

.container p {
    color: white;
    text-align: center;
    max-width: 360px;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
}

@media (min-width: 991px) {
    .container {
        height: 50vh;
    }
}
