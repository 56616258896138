.container {
  padding: 20px 0;
  background-color: #efefef;
}

.responsiveContent {
  composes: responsiveContent from '../../sharedStyles/layout.css';
  margin-bottom: 10px;
}

.heading {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 400;
  text-transform: none;
  margin-top: 15px;
}

.infoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.thumbnail {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-size: cover;
  margin-right: 18px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.18824);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.18824);
}

.routeTitle {
  text-transform: none;
  font-size: 28px;
  margin: 4px 0 8px;
}

.routeSubtitle {
  /*margin-bottom: 10px;*/
}

@media (max-width: 767px) {

  .routeTitle {
    font-size: 20px;
  }

  .heading {
    text-align: center;
  }

  .infoContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 767px) {

  .routeTitle {
    text-align: center;
  }
}