@value brandGreen, gray, patronDarkBlue from '../../sharedStyles/colors.css';

.headerContainer {
    composes: responsiveContainer from '../../sharedStyles/layout.css';
    background-color: white;
    height: 50px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.header {
    composes: responsiveContent from '../../sharedStyles/layout.css';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 50px;
}

.logo {
    height: 24px;
}

.bannerRegion {
    margin-bottom: 60px;
}

.body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    composes: responsiveContent from '../../sharedStyles/layout.css';
    margin-top: 20px;
}

.formBox {
    width: 700px;
    background-color: white;
    -webkit-box-shadow: 1px 1px 1px 0 #d6d6d6;
            box-shadow: 1px 1px 1px 0 #d6d6d6;
    border-radius: 3px;
    padding: 20px;
    margin-bottom: 60px;
}

.formBox h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .formBox {
        width: 100%;
    }
}
