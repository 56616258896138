.responsiveContainer {
  /*display: flex;*/
  /*justify-content: center;*/
}

.responsiveContent {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1 1;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {
  .responsiveContent {
    max-width: 100%;
    padding: 0 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsiveContent {
    max-width: 730px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .responsiveContent {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  .responsiveContent {
    max-width: 1150px;
  }
}

