@value brandGreen from '../../sharedStyles/colors.css';
@value patronDarkBlue from '../../sharedStyles/colors.css';
.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}
@media (min-width: 992px) {
  .container {
    width: 50%;
  }
}
.card {
  position: relative;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 4px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  border: 2px solid white;
  -webkit-box-shadow: 0 0 5px 0 #dddddd;
          box-shadow: 0 0 5px 0 #dddddd;
}
.associatedCard {
  composes: card;
  border-color: patronDarkBlue;
  /*filter: none;*/
}
.publicCard {
  composes: card;
  border-color: patronDarkBlue;
  /*filter: none;*/
}
.associatedIcon {
  position: absolute;
  top: 37%;
  left: 10px;
  font-size: 15px;
  color: patronDarkBlue;
}
.publicIcon {
  composes: associatedIcon;
}
.organizationLogo {
  min-width: 80%;
  min-height: 40px;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.organizationNameContainer {
  min-width: 80%;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.organizationName {
  font-size: 14px;
  font-weight: 300;
}
.buttonRegion {
  margin-bottom: 80px;
}