@value brandPrimary, brandGreen, gray, patronDarkBlue from '../../sharedStyles/colors.css';

.headerContainer {
  composes: responsiveContainer from "../../sharedStyles/layout.css";
  background-color: white;
  height: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header {
  composes: responsiveContent from "../../sharedStyles/layout.css";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
}

.bannerRegion {
  margin-bottom: 80px;
}

.logo {
  height: 24px;
}

.body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  composes: responsiveContent from "../../sharedStyles/layout.css";
  margin-top: 20px;
  margin-bottom: 60px;
}

.body h1 {
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 20px;
}

.bodyRight h1 {
  font-size: 20px;
}

.bodyLeft {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #ddd;
}

.bodyRight {
  padding-left: 20px;
}

.bodyLeft,
.bodyRight {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.signInContainer {
  background-color: white;
  padding: 20px;
  margin-bottom: 60px;
}

.signInRegion {
  /*text-align: center;*/
}

.locationRegion {
  margin-bottom: 40px;
}

.locationButton {
  color: #333;
  font-family: "Nunito", sans-serif;
  text-transform: uppercase;
  padding: 8px 24px;
  font-size: 14px;
  border: 2px solid #127da9;
  border-radius: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(255, 255, 255, .5);
  margin: 0 auto;
}

.locationInfoButton {
  color: #333;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #333;
  width: 22px;
  height: 22px;
  margin-left: 18px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.locationButton :global(.fa) {
  font-size: 16px;
  margin-right: 4px;
  color: #127da9;
}

.infoButton {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 24px;
  height: 24px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #333;
  color: #333;
  border-radius: 50%;
  background-color: transparent;
  margin-left: 20px;
}

.accessibleLibrariesRegion {
  margin-bottom: 20px;
  margin-top: 20px;
}

.accessibleLibrariesRegion p {
  font-size: 16px;
  margin-bottom: 30px;
}

.organizationCardsRegion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.searchForLibrariesRegion {
  margin-bottom: 60px;
}

.librarySelect {
  width: 100%;
}

.requestAccessLink {
  font-weight: 400;
  font-size: 14px;
  background: none;
  color: brandPrimary;
  border: none;
  padding: 0;
}

.requestAccessLink:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {

  .bodyRight h1 {
    font-size: 17px;
  }

  .bodyLeft {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }

  .body h1 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .body h1 {
    text-align: center;
  }

  .bodyLeft,
  .bodyRight {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; /* IE 11 fix */
  }

  .bodyLeft {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding-right: 0;
    border-right: none;
    margin-right: 0;
  }

  .bodyRight {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
  }

  .signInContainer {
    margin-top: 30px;
  }

  .locationRegion {
    text-align: center;
  }

  .accessibleLibrariesRegion {
    text-align: center;
  }

  .organizationCardsRegion {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
